import { html, state } from 'lit-element'
import { ShellElement } from '../../../common/shell-element'
import { t } from '../../../directives/translate'
import scheduleSettingsStyles from './schedule-settings.styles.scss'
import { sampleCurrentUserSchedule } from '../schedule-manager/samples'
import { environment } from '../../../environments'
import { type RadioGroupComponent } from '@getgo/chameleon-web'
import { ScheduleStatus } from '../schedule-manager/models'

export class GoToScheduleSettings extends ShellElement {
  static readonly tagName = 'goto-schedule-settings'

  @state() private isDirty: boolean = false
  @state() private isScheduleEnabled: boolean = false

  static get styles() {
    return scheduleSettingsStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.isScheduleEnabled = this.getEnabled()
  }

  private getTimeZone() {
    // this should be changed to get it from the schedule manager with the getCurrentUserSchedule function once we plugin the manager to this file
    return sampleCurrentUserSchedule.timezone
  }

  private getEnabled() {
    // this should be changed to get it from the schedule manager with the getCurrentUserSchedule function once we plugin the manager to this file
    return sampleCurrentUserSchedule.enabled
  }

  private getFooterClass() {
    return this.isDirty ? 'footer-visible' : 'footer-hidden'
  }

  private getTimeZoneColor() {
    return this.isScheduleEnabled ? 'type-color-default' : 'type-color-disabled'
  }

  private handleScheduleStatusChange(event: Event) {
    const scheduleStatusRadioGroup = event.currentTarget as RadioGroupComponent
    const newScheduleEnabled = scheduleStatusRadioGroup.value === ScheduleStatus.WORK_SCHEDULE
    this.isDirty = this.isScheduleEnabled !== newScheduleEnabled
    this.isScheduleEnabled = newScheduleEnabled
  }

  render() {
    return html`
      <form class="schedule-settings-page">
        <div class="schedule-settings-scrollable-content">
          <chameleon-typography class="settings-header" tag="h1" variant="heading-large">
            ${t('Schedule')}
          </chameleon-typography>
          <div class="schedule-settings-container">
            <chameleon-typography variant="body-medium" id="set-description"
              >${t("Set when you're available to receive calls and notifications")}</chameleon-typography
            >
            <div class="work-section">
              <chameleon-radio-group
                label-id="set-description"
                value=${this.isScheduleEnabled ? ScheduleStatus.WORK_SCHEDULE : ScheduleStatus.NO_HOURS_SET}
                @change=${this.handleScheduleStatusChange}
              >
                <chameleon-radio-button value=${ScheduleStatus.NO_HOURS_SET}
                  >${t('No hours set')}</chameleon-radio-button
                >
                <chameleon-radio-button value=${ScheduleStatus.WORK_SCHEDULE}>
                  ${t('Work schedule')}</chameleon-radio-button
                >
              </chameleon-radio-group>
              <goto-schedule-work-hours></goto-schedule-work-hours>
            </div>
            <div class="custom-section">
              <goto-schedule-custom-hours ?isScheduleEnabled=${this.isScheduleEnabled}></goto-schedule-custom-hours>
            </div>
            <div class="time-zone-section">
              <div class="time-zone-section-header">
                <chameleon-typography class="small-header" variant="body-medium-strong"
                  >${t('Time zone')}</chameleon-typography
                >
                <div class="time-zone-set">
                  <chameleon-typography variant="body-medium" .color=${this.getTimeZoneColor()}
                    >${t('Profile time zone: ')}${this.getTimeZone()}</chameleon-typography
                  ><chameleon-button-link
                    href="${environment().profileUrl}"
                    target="blank"
                    variant="secondary"
                    size="medium"
                    ?disabled=${!this.isScheduleEnabled}
                    >${t('Change my time zone')}</chameleon-button-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=${this.getFooterClass()}>
          <chameleon-button size="medium" variant="tertiary">${t('Cancel')}</chameleon-button>
          <chameleon-button size="medium">${t('Save')}</chameleon-button>
        </div>
      </form>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleSettings.tagName]: GoToScheduleSettings
  }
}
