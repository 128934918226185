import { SearchResultPreviewItem } from './packages/global-search/search-result-preview-item/search-result-preview-item'
import type { PartialShellCustomElementConstructorDictionary } from './core/custom-component/models'
import { GoToAccountSwitcher } from './packages/account-switcher/account-switcher'
import { GoToAppLaunchSettings } from './packages/app-launch-settings/app-launch-settings'
import { GoToAppSwitcher } from './packages/app-switcher/app-switcher'
import { GoToAvatar } from './packages/avatar'
import { GoToAvatarContainer } from './packages/avatar-container/avatar-container'
import { GoToAvatarPopoverMenu } from './packages/avatar-popover-menu/avatar-popover-menu'
import { GoToCalendarConnected } from './packages/calendar-connected/calendar-connected'
import { GoToCalendarSettings } from './packages/calendar/calendar-settings'
import { GoToContextMenu } from './packages/context-menu/context-menu'
import { CounterBadgeElement } from './packages/counter-badge/counter-badge'
import { GoToDatePicker } from './packages/date-picker'
import { GoToCreateIncident } from './packages/dialogs/create-incident/create-incident'
import { EditUserPresenceNoteDialog } from './packages/dialogs/edit-user-presence-note-dialog'
import { UserPresenceNoteField } from './packages/dialogs/edit-user-presence-note-dialog/user-presence-note-field'
import { GotoError } from './packages/error-page'
import { GoToFilePicker } from './packages/file-picker'
import { GoToKeyboardShortcutSection } from './packages/keyboard-shortcut/keyboard-shortcut-section'
import { GoToKeyboardShortcutSettings } from './packages/keyboard-shortcut/keyboard-shortcut-settings'
import { GoToKeyboardShortcutInput } from './packages/keyboard-shortcut/keyboard-shortcut-input'
import { GoToInspectorPanel } from './packages/inspector-panel/inspector-panel'
import { LanguageSelectElement } from './packages/language-select/language-select'
import { LanguageSettingsElement } from './packages/language-settings/language-settings'
import { GoToMeetingIdInput } from './packages/meeting-id-input/meeting-id-input'
import { GoToNavRail } from './packages/navigation/navigation-rail/navigation-rail'
import { GoToNotificationsSettings } from './packages/notifications-settings/notifications-settings'
import { GoToBannerNotification } from './packages/notifications/banner-notifications/banner-notification'
import { GoToModalNotification } from './packages/notifications/modal-notifications/modal-notification'
import { GoToSnackbarNotification } from './packages/notifications/snackbar-notifications/snackbar-notification'
import { GoToPresenceIndicator } from './packages/presence-indicator'
import { GotoPresenceSelector } from './packages/presence-selector/presence-selector'
import { GoToGeneralSettings } from './packages/settings/general-settings'
import { GoToSettings } from './packages/settings/settings'
import { GoToSpecificSettings } from './packages/settings/specific-settings'
import { GoToSpecificSettingsAccordion } from './packages/settings/specific-settings/specific-settings-accordion'
import { GoToShellController } from './packages/shell-controller/shell-controller'
import { ShellLanding } from './packages/shell-landing/shell-landing'
import { TrialBanner } from './packages/banner/trial-banner'
import { TermsOfService } from './packages/terms-of-service/terms-of-service'
import { TopbarLogo } from './packages/topbar/logo/topbar-logo'
import { GoToTopbar } from './packages/topbar/topbar'
import { GoToUnauthenticatedError } from './packages/unauthenticated-error/unauthenticated-error'
import { GoToAvatarUpdateMenuItem, GoToUpdateChip, GoToUpdateIcon } from './packages/update-app'
import { GotoUserNoteIcon } from './packages/user-note-icon/user-note-icon'
import { GoToUserNoteInput } from './packages/user-note-input/user-note-input'
import { VersionElement } from './packages/version/version-element'
import { SearchResult } from './packages/global-search/search-result/search-result'
import { GoToGlobalSearchField } from './packages/global-search/search-field/global-search-field'
import { GlobalSearchActions } from './packages/global-search/search-actions/global-search-actions'
import { SearchResultsPage } from './packages/global-search/search-results-page/search-results-page'
import { SearchHistory } from './packages/global-search/search-history/search-history'
import { SearchError } from './packages/global-search/search-error/search-error'
import { GoToKeyboardShortcutSectionRow } from './packages/keyboard-shortcut/keyboard-shortcut-section-row'
import { WindowSupportBanner } from './packages/banner/windows-support-banner'
import { GoToAppearanceSettings } from './packages/appearance/appearance-settings'
import { GotoLoadingState } from './packages/loading-state/component'
import { SearchLoadingState } from './packages/global-search/search-loading-state/search-loading-state'
import { GoToTopbarHelpMenu } from './packages/topbar/topbar-help-menu'
import { MacOSSupportBanner } from './packages/banner/macos-support-banner'
import { GoToIntegrationTopbar } from './packages/integration-topbar/integration-topbar'
import { GoToIntegrationUserMenu } from './packages/integration-user-menu/integration-user-menu'
import { IntegrationDisconnectedPage } from './packages/integration-disconnected-page/integration-disconnected-page'
import { ShellLandingController } from './packages/shell-landing/shell-landing-controller'
import { GoToNavBar } from './packages/navigation/navigation-bar/navigation-bar'
import { GotoAssistant } from './packages/goto-assistant/goto-assistant'
import { BlockedExtensionsBanner } from './packages/banner/blocked-extensions-banner'
import { IntegrationStartPlugin } from './packages/integration-start-plugin/integration-start-plugin'
import { TeamsMobileLanding } from './packages/shell-landing/teams-mobile-landing-page'
import { MobileBadge } from './packages/mobile-badge/mobile-badge'
import { IntegrationConnectedLanding } from './packages/integration-connected-landing/integration-connected-landing'
import { IntegrationRedirectProxy } from './packages/integration-redirect-proxy/integration-redirect-proxy'
import { GotoTextHighlight } from './packages/highlight/highlight-component'
import { GoToScheduleSettings } from './packages/schedule-settings/schedule-settings-page/schedule-settings'
import { GoToScheduleWorkHours } from './packages/schedule-settings/schedule-settings-page/schedule-work-hours'
import { GoToScheduleCustomHours } from './packages/schedule-settings/schedule-settings-page/schedule-custom-hours'
import { GoToScheduleCustomHoursModal } from './packages/schedule-settings/schedule-custom-hours-modal/schedule-custom-hours-modal'
import { GoToScheduleWorkHoursModalRow } from './packages/schedule-settings/schedule-work-hours-modal/schedule-work-hours-modal-row'
import { GoToScheduleWorkHoursModal } from './packages/schedule-settings/schedule-work-hours-modal/schedule-work-hours-modal'

const customComponentDictionary = {
  [GoToKeyboardShortcutSettings.tagName]: GoToKeyboardShortcutSettings,
  [GoToKeyboardShortcutSection.tagName]: GoToKeyboardShortcutSection,
  [GoToKeyboardShortcutSectionRow.tagName]: GoToKeyboardShortcutSectionRow,
  [GoToKeyboardShortcutInput.tagName]: GoToKeyboardShortcutInput,
  [GoToCalendarSettings.tagName]: GoToCalendarSettings,
  [GoToAppearanceSettings.tagName]: GoToAppearanceSettings,
  [GoToAppLaunchSettings.tagName]: GoToAppLaunchSettings,
  [GoToNotificationsSettings.tagName]: GoToNotificationsSettings,
  [GoToScheduleSettings.tagName]: GoToScheduleSettings,
  [LanguageSettingsElement.tagName]: LanguageSettingsElement,
  [LanguageSelectElement.tagName]: LanguageSelectElement,
  [GoToSpecificSettings.tagName]: GoToSpecificSettings,
  [GoToSpecificSettingsAccordion.tagName]: GoToSpecificSettingsAccordion,
  [GoToGeneralSettings.tagName]: GoToGeneralSettings,
  [GoToSettings.tagName]: GoToSettings,
  [GoToNavRail.tagName]: GoToNavRail,
  [CounterBadgeElement.tagName]: CounterBadgeElement,
  [GoToAppSwitcher.tagName]: GoToAppSwitcher,
  [GoToContextMenu.tagName]: GoToContextMenu,
  [GoToAvatar.tagName]: GoToAvatar,
  [GoToPresenceIndicator.tagName]: GoToPresenceIndicator,
  [GoToAvatarPopoverMenu.tagName]: GoToAvatarPopoverMenu,
  [GoToTopbarHelpMenu.tagName]: GoToTopbarHelpMenu,
  [GoToUserNoteInput.tagName]: GoToUserNoteInput,
  [GoToAvatarUpdateMenuItem.tagName]: GoToAvatarUpdateMenuItem,
  [GoToBannerNotification.tagName]: GoToBannerNotification,
  [GoToModalNotification.tagName]: GoToModalNotification,
  [UserPresenceNoteField.tagName]: UserPresenceNoteField,
  [GoToSnackbarNotification.tagName]: GoToSnackbarNotification,
  [GoToTopbar.tagName]: GoToTopbar,
  [GoToIntegrationTopbar.tagName]: GoToIntegrationTopbar,
  [GoToIntegrationUserMenu.tagName]: GoToIntegrationUserMenu,
  [GoToUpdateChip.tagName]: GoToUpdateChip,
  [GotoUserNoteIcon.tagName]: GotoUserNoteIcon,
  [GoToAvatarContainer.tagName]: GoToAvatarContainer,
  [ShellLanding.tagName]: ShellLanding,
  [ShellLandingController.tagName]: ShellLandingController,
  [TeamsMobileLanding.tagName]: TeamsMobileLanding,
  [IntegrationDisconnectedPage.tagName]: IntegrationDisconnectedPage,
  [IntegrationConnectedLanding.tagName]: IntegrationConnectedLanding,
  [IntegrationStartPlugin.tagName]: IntegrationStartPlugin,
  [IntegrationRedirectProxy.tagName]: IntegrationRedirectProxy,
  [GoToAccountSwitcher.tagName]: GoToAccountSwitcher,
  [EditUserPresenceNoteDialog.tagName]: EditUserPresenceNoteDialog,
  [TopbarLogo.tagName]: TopbarLogo,
  [GoToCreateIncident.tagName]: GoToCreateIncident,
  [GoToCalendarConnected.tagName]: GoToCalendarConnected,
  [GotoPresenceSelector.tagName]: GotoPresenceSelector,
  [GoToUpdateIcon.tagName]: GoToUpdateIcon,
  [VersionElement.tagName]: VersionElement,
  [GoToShellController.tagName]: GoToShellController,
  [GoToFilePicker.tagName]: GoToFilePicker,
  [GoToDatePicker.tagName]: GoToDatePicker,
  [GoToUnauthenticatedError.tagName]: GoToUnauthenticatedError,
  [TermsOfService.tagName]: TermsOfService,
  [GotoError.tagName]: GotoError,
  [GoToMeetingIdInput.tagName]: GoToMeetingIdInput,
  [GoToInspectorPanel.tagName]: GoToInspectorPanel,
  [TrialBanner.tagName]: TrialBanner,
  [WindowSupportBanner.tagName]: WindowSupportBanner,
  [MacOSSupportBanner.tagName]: MacOSSupportBanner,
  [BlockedExtensionsBanner.tagName]: BlockedExtensionsBanner,
  [GoToGlobalSearchField.tagName]: GoToGlobalSearchField,
  [SearchResult.tagName]: SearchResult,
  [GlobalSearchActions.tagName]: GlobalSearchActions,
  [SearchResultPreviewItem.tagName]: SearchResultPreviewItem,
  [SearchResultsPage.tagName]: SearchResultsPage,
  [SearchHistory.tagName]: SearchHistory,
  [SearchError.tagName]: SearchError,
  [SearchLoadingState.tagName]: SearchLoadingState,
  [GotoLoadingState.tagName]: GotoLoadingState,
  [GoToNavBar.tagName]: GoToNavBar,
  [GotoAssistant.tagName]: GotoAssistant,
  [MobileBadge.tagName]: MobileBadge,
  [GotoTextHighlight.tagName]: GotoTextHighlight,
  [GoToScheduleWorkHours.tagName]: GoToScheduleWorkHours,
  [GoToScheduleCustomHours.tagName]: GoToScheduleCustomHours,
  [GoToScheduleCustomHoursModal.tagName]: GoToScheduleCustomHoursModal,
  [GoToScheduleWorkHoursModalRow.tagName]: GoToScheduleWorkHoursModalRow,
  [GoToScheduleWorkHoursModal.tagName]: GoToScheduleWorkHoursModal,
}

export const customComponents: PartialShellCustomElementConstructorDictionary<keyof typeof customComponentDictionary> =
  customComponentDictionary
