import { formatDateTime } from '../../services/i18n'

import { dateTimeFormatOptions } from './schedule-settings-models'

const isISOTimeStringValid = (timeString: string) => {
  const regExp = /^([0-9]|[01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/
  return regExp.test(timeString)
}

const parseTime = (timeString: string) => {
  if (!isISOTimeStringValid(timeString)) {
    return null
  }

  return timeString.split(':').map(Number)
}

export const formatISOTime = (ISOTime: string): string => {
  const parsedTime = parseTime(ISOTime)
  if (parsedTime) {
    const [hours, minutes, seconds] = parsedTime
    return formatDateTime(new Date().setHours(hours, minutes, seconds), dateTimeFormatOptions)
  }

  return formatDateTime(new Date().setHours(0, 0, 0), dateTimeFormatOptions)
}

export const initialTimeOfTheDay = '00:00:00'

export const lastTimeOfTheDay = '23:59:59'

export const getListOfTimeForOneDayWithInterval = (intervalInMinutes: number) => {
  const result = []

  const currentTime = new Date()
  currentTime.setHours(0, 0, 0, 0)

  const originalDay = currentTime.getDay()

  while (currentTime.getDay() === originalDay) {
    result.push(currentTime.toTimeString().split(' ')[0])
    currentTime.setMinutes(currentTime.getMinutes() + intervalInMinutes)
  }
  return result
}
