import { ShellElement } from '../../../common/shell-element'
import { html } from 'lit-element'
import { t } from '../../../directives/translate'
import scheduleWorkHoursStyles from './schedule-work-hours.styles.scss'
import { SVG_EDIT_OUTLINED } from '@getgo/chameleon-icons'
import { getDialogService } from '../../../services/dialog/public-facade'
import { getDocument } from '../../../common/dom-helpers'
import {
  CLOSE_WORK_HOURS_MODAL,
  GoToScheduleWorkHoursModal,
  MODIFIED_WORK_PERIODS,
  type ModifiedWorkHoursEvent,
} from '../schedule-work-hours-modal/schedule-work-hours-modal'

export class GoToScheduleWorkHours extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours'

  private workHoursModal: GoToScheduleWorkHoursModal | undefined

  static get styles() {
    return scheduleWorkHoursStyles
  }

  private createWorkHoursModal() {
    this.workHoursModal = getDocument().createElement(GoToScheduleWorkHoursModal.tagName)
    this.workHoursModal.addEventListener(MODIFIED_WORK_PERIODS, this.handleModifiedWorkPeriods)
    this.workHoursModal.addEventListener(CLOSE_WORK_HOURS_MODAL, this.handleCloseWorkHoursModal)
    return this.workHoursModal
  }

  handleModifiedWorkPeriods = (event: CustomEvent<ModifiedWorkHoursEvent>) => {
    // Temporary. The data received will be handled in a separate task
    // TODO: https://jira.ops.expertcity.com/browse/SCORE-3486
    console.log('Modified work hours', event.detail.modifiedWorkPeriods)
    this.closeWorkHoursModal()
  }

  handleCloseWorkHoursModal = () => {
    this.closeWorkHoursModal()
  }

  private closeWorkHoursModal() {
    // Removing the listeners when closing an instance of the modal. This is to avoid memory leaks as the modal can be opened and closed multiple times
    this.workHoursModal?.removeEventListener(MODIFIED_WORK_PERIODS, this.handleModifiedWorkPeriods)
    this.workHoursModal?.removeEventListener(CLOSE_WORK_HOURS_MODAL, this.handleCloseWorkHoursModal)
    this.workHoursModal = undefined
    getDialogService().close()
  }

  render() {
    return html`
      <div class="work-hours-header">
        <chameleon-typography class="small-header" variant="heading-small" tag="h3"
          >${t('Work hours')}</chameleon-typography
        >
        <chameleon-typography variant="body-small" color="type-color-secondary" id="set-work-hours-label"
          >${t('Set your regular availability for the week.')}</chameleon-typography
        >
      </div>
      <div class="work-hours-schedule">
        <div>WORK HOURS GO HERE</div>
        <chameleon-icon-button
          class="edit-work-hours-button"
          label-id="set-work-hours-label"
          @click=${() => {
            getDialogService().open(this.createWorkHoursModal())
          }}
          ><chameleon-svg>${SVG_EDIT_OUTLINED}</chameleon-svg></chameleon-icon-button
        >
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleWorkHours.tagName]: GoToScheduleWorkHours
  }
}
