/* istanbul ignore file */

import { TimeZoneSource, type UserSchedule } from './models'

export const sampleCurrentUserSchedule: UserSchedule = {
  userId: 'pbx-user-id',
  userKey: '1234567890',
  orgId: 'my org',
  accountKey: '6666666',
  timezoneSource: TimeZoneSource.USER,
  timezone: 'America/New_York',
  workPeriods: [
    { dayOfWeek: 'MONDAY', startTime: '10:15:00', endTime: '16:15:00' },
    { dayOfWeek: 'TUESDAY', startTime: '9:15:00', endTime: '13:15:00' },
    { dayOfWeek: 'WEDNESDAY', startTime: '11:15:00', endTime: '18:15:00' },
    { dayOfWeek: 'THURSDAY', startTime: '10:30:00', endTime: '16:30:00' },
    { dayOfWeek: 'FRIDAY', startTime: '10:00:00', endTime: '12:15:00' },
    { dayOfWeek: 'SATURDAY', startTime: '13:00:00', endTime: '15:00:00' },
  ],
  oneTimePeriods: [
    { name: 'Spring break! Woo!', startDate: '2025-03-15T00:00:00', endDate: '2025-03-30T00:00:00', dndEnabled: true },
    { name: 'Long weekend', startDate: '2025-04-18T00:00:00', endDate: '2025-04-21T00:00:00', dndEnabled: true },
    { name: 'Winter holiday', startDate: '2024-12-22T00:00:00', endDate: '2024-12-31T00:00:00', dndEnabled: true },
  ],
  dndAutomationEnabled: true,
  enabled: true,
  userCanEdit: true,
}

export const sampleDefaultUserSchedule = {
  userId: 'default-pbx-user-id',
  userKey: '0987654321',
  orgId: 'my org',
  accountKey: '11111111',
  timezoneSource: TimeZoneSource.USER,
  timezone: 'America/New_York',
  workPeriods: [],
  oneTimePeriods: [],
  dndAutomationEnabled: false,
  enabled: false,
  userCanEdit: true,
} as UserSchedule

export const sampleNewWorkPeriods = [
  { dayOfWeek: 'MONDAY', startTime: '10:15:00', endTime: '16:15:00' },
  { dayOfWeek: 'TUESDAY', startTime: '9:15:00', endTime: '13:15:00' },
  { dayOfWeek: 'WEDNESDAY', startTime: '11:15:00', endTime: '18:15:00' },
  { dayOfWeek: 'THURSDAY', startTime: '10:30:00', endTime: '16:30:00' },
  { dayOfWeek: 'SATURDAY', startTime: '13:00:00', endTime: '15:00:00' },
]

// This is a temporary function to get the sample user schedule. This can be removed when a call to the shell bff is available
export const getSampleCurrentUserSchedule = (): UserSchedule | undefined => sampleCurrentUserSchedule

// This is a temporary function to update the user schedule. This can be removed when a call to the shell bff is available
export const updateUserScheduleBFF = (modifiedSchedule?: UserSchedule): Promise<void> => {
  if (typeof modifiedSchedule !== 'object') {
    throw new Error('modifiedSchedule must be an object')
  }
  return Promise.resolve()
}
