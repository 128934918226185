import type { GoToExperienceConfig } from '../../experiences'
import { serializeForStorage } from '../../common/storage'
import { assertsIsUrl, isInPathname } from '../../common/url-helper'
import {
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
  setToLocalStorage,
  setToSessionStorage,
} from '../../common/dom-helpers'
import { SessionStorageKeys } from '../../environments'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import { refreshTokenCounterKey } from './constants'
import { getShellLogger } from '../../common/logger'

export const createRandomString = () => {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-_~.'
  let random = ''
  const randomValues = Array.from(globalThis.crypto.getRandomValues(new Uint8Array(43)))
  randomValues.forEach(v => (random += charset[v % charset.length]))
  return random
}

export const sha256 = (s: string) => crypto.subtle.digest({ name: 'SHA-256' }, new TextEncoder().encode(s))

export const bufferToBase64UrlEncoded = (input: ArrayBuffer) =>
  urlEncodeB64(btoa(String.fromCharCode(...Array.from(new Uint8Array(input)))))

const urlEncodeB64 = (input: string) => {
  const b64Chars: { readonly [index: string]: string } = { '+': '-', '/': '_', '=': '' }
  return input.replace(/[+/=]/g, (m: string) => b64Chars[m])
}

export const getRefreshTokenLimit = () => {
  const flags = getFeatureFlagValue(FeatureFlagsVariations.PKCE_REFRESH_TOKENS, {
    enabled: false,
    maxRefreshTokens: 0,
  })
  return flags?.maxRefreshTokens ?? 0
}

export const initRefreshTokenCounter = () => {
  setToLocalStorage(refreshTokenCounterKey, '0')
}

export const getRefreshTokenCounter = () => Number(getFromLocalStorage(refreshTokenCounterKey) ?? 0)

export const canQueryNewRefreshToken = () => getRefreshTokenCounter() < getRefreshTokenLimit()

export const incrementRefeshTokensCount = () => {
  const incrementedValue = getRefreshTokenCounter() + 1
  setToLocalStorage(refreshTokenCounterKey, JSON.stringify(incrementedValue))
}

export const decodeAndParse = (s: string) => {
  if (s?.length) {
    try {
      return JSON.parse(decodeURIComponent(s))
    } catch (e) {
      getShellLogger().error('Cannot decode and parse this string', s)
      throw new Error('Cannot decode and parse a string')
    }
  }
  throw new Error('Decode and parse an empty string')
}

export const stringifyAndEncode = (state: { readonly [key: string]: string | number | boolean }) =>
  encodeURIComponent(JSON.stringify(state))

/* istanbul ignore next jsdom does not support window navigation mocking */
export function currentRouteIsGuarded(unguardedRoutes: GoToExperienceConfig['unguardedRoutes']) {
  return !unguardedRoutes?.some(isInPathname(location.pathname))
}

export const decodeBase64URIParameters = (base64Parameters: string) =>
  JSON.parse(decodeURIComponent(atob(base64Parameters)))

export const encodeBase64URIParameters = (parameters: unknown) =>
  btoa(encodeURIComponent(serializeForStorage(parameters)))

export const getInflightRequest = () => getFromSessionStorage(SessionStorageKeys.inflightRequest)

export const storeInflightRequest = (inflightRequest: string) => {
  try {
    assertsIsUrl(inflightRequest)
    setToSessionStorage(SessionStorageKeys.inflightRequest, inflightRequest)
  } catch (e) {
    // do nothing
  }
}

export const removeInflightRequestFromSessionStorage = () => {
  removeFromSessionStorage(SessionStorageKeys.inflightRequest)
}

/**
 * @returns the parsed string and catches thrown errors if the given value is not parseable.
 * NB: the returned value is not type guarded so the return value is not guaranteed to be the expected type.
 **/
export const safeJsonParse = <T>(value: string, defaultValue = {}) => {
  try {
    return (JSON.parse(value) ?? {}) as T
  } catch {
    return defaultValue as T
  }
}
