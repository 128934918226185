import { ShellElement } from '../../../common/shell-element'
import { html, property, state } from 'lit-element'
import { daysOfWeekLocalized } from '../schedule-settings-models'
import styles from './schedule-work-hours-modal-row.styles.scss'
import { formatISOTime, getListOfTimeForOneDayWithInterval, lastTimeOfTheDay } from '../schedule-settings-utils'
import { t } from '../../../directives/translate'
import { type ChangeEvent } from 'react'
import { type Part } from 'lit-html'
import { ifDefined } from 'lit-html/directives/if-defined'
import { type DayOfWeek, type WorkPeriod } from '../schedule-manager/models'

export class GoToScheduleWorkHoursModalRow extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours-modal-row'

  static get styles() {
    return styles
  }

  @property({ type: Object }) currentWorkPeriod: WorkPeriod | undefined
  @property({ type: Boolean }) error: boolean = false
  @property({ type: Boolean }) isFirstRow = false
  @property({ type: Function }) change: (workPeriod: Partial<WorkPeriod>, isDirty: boolean) => void = () => ''

  private endTimeOptions: Array<{ value: string; hidden: boolean }> = []

  private isDirty: boolean = false
  private startTimeOptions: Array<string> = []
  private daysOfTheWeek: Array<DayOfWeek> = []
  @state() modifiedWorkPeriod: Partial<WorkPeriod> = {}

  connectedCallback() {
    super.connectedCallback()
    this.modifiedWorkPeriod = this?.currentWorkPeriod ?? {}
    this.startTimeOptions = this.getStartTimeOptions()
    this.endTimeOptions = this.getEndTimeOptions()
    this.daysOfTheWeek = this.getDaysOfTheWeekOptions()

    if (this.currentWorkPeriod?.startTime) {
      this.hideInvalidEndTimeOptions(this.currentWorkPeriod.startTime)
    }
  }

  private getStartTimeOptions() {
    return getListOfTimeForOneDayWithInterval(15)
  }

  private getEndTimeOptions() {
    return getListOfTimeForOneDayWithInterval(15)
      .map(int => ({ value: int, hidden: false }))
      .concat({ value: lastTimeOfTheDay, hidden: false })
      .slice(1)
  }

  private getDaysOfTheWeekOptions(): Array<DayOfWeek> {
    return Object.keys(daysOfWeekLocalized) as Array<DayOfWeek>
  }

  private hideInvalidEndTimeOptions(startTime: string) {
    const indexTime = this.endTimeOptions.findIndex(({ value }) => value === startTime)
    this.endTimeOptions = this.endTimeOptions.map((option, index) => ({ ...option, hidden: index <= indexTime }))
  }

  private getValidEndTime(startTime: string): string | undefined {
    const startIndex = this.endTimeOptions.findIndex(({ value }) => value === startTime)
    const endIndex = this.endTimeOptions.findIndex(({ value }) => value === this.modifiedWorkPeriod.endTime)
    if (startIndex !== -1 && endIndex !== -1 && startIndex >= endIndex) {
      return this.endTimeOptions[startIndex + 1].value
    }
    return this.modifiedWorkPeriod.endTime
  }

  private handleChangeStartTime(event: ChangeEvent<HTMLSelectElement>) {
    this.isDirty = true
    const value = event.currentTarget.value
    this.hideInvalidEndTimeOptions(value)
    this.modifiedWorkPeriod = {
      ...this.modifiedWorkPeriod,
      startTime: value,
      endTime: this.getValidEndTime(value),
    }
    this.triggerChangeEvent()
  }

  private handleChangeEndTime(event: ChangeEvent<HTMLSelectElement>) {
    this.isDirty = true
    this.modifiedWorkPeriod = { ...this.modifiedWorkPeriod, endTime: event.currentTarget.value }
    this.triggerChangeEvent()
  }

  private handleChangeDayOfTheWeek(event: ChangeEvent<HTMLSelectElement>) {
    this.isDirty = true
    this.modifiedWorkPeriod.dayOfWeek = event.currentTarget.value as DayOfWeek
    this.triggerChangeEvent()
  }

  private triggerChangeEvent() {
    this.change(this.modifiedWorkPeriod, this.isDirty)
  }

  private renderSelectOption(value: string, displayValue: string | ((part: Part) => void), hidden: boolean) {
    return html`<chameleon-option class=${hidden ? 'hidden' : ''} value=${value}>${displayValue}</chameleon-option>`
  }

  private renderDay() {
    return html`
      <chameleon-select
        in-dialog
        label=${this.isFirstRow ? t('Day') : ''}
        aria-label=${this.isFirstRow ? '' : t('Day')}
        class="day-select"
        selected-value=${ifDefined(this.modifiedWorkPeriod?.dayOfWeek)}
        ?error=${this.error}
        @change=${this.handleChangeDayOfTheWeek}
      >
        <chameleon-option class="hidden" value="">${t('Day')}</chameleon-option>
        ${this.daysOfTheWeek.map(value => this.renderSelectOption(value, t(daysOfWeekLocalized[value]), false))}
      </chameleon-select>
    `
  }

  private renderStartTime() {
    return html` <chameleon-select
      in-dialog
      label=${this.isFirstRow ? t('Start') : ''}
      aria-label=${this.isFirstRow ? '' : t('Start')}
      class="select-start-time time-select"
      selected-value=${ifDefined(this.modifiedWorkPeriod?.startTime)}
      ?error=${this.error}
      @change=${this.handleChangeStartTime}
    >
      <chameleon-option class="hidden" value="">${t('Time')}</chameleon-option>
      ${this.startTimeOptions.map(value => this.renderSelectOption(value, formatISOTime(value), false))}
    </chameleon-select>`
  }

  private renderEndTime() {
    return html` <chameleon-select
      in-dialog
      label=${this.isFirstRow ? t('End') : ''}
      aria-label=${this.isFirstRow ? '' : t('End')}
      class="select-end-time time-select"
      selected-value=${ifDefined(this.modifiedWorkPeriod?.endTime)}
      ?error=${this.error}
      @change=${this.handleChangeEndTime}
    >
      <chameleon-option class="hidden" value="">${t('Time')}</chameleon-option>
      ${this.endTimeOptions.map(({ value, hidden }) => this.renderSelectOption(value, formatISOTime(value), hidden))}
    </chameleon-select>`
  }

  render() {
    return html`
      <div class="work-hours-row">${this.renderDay()} ${this.renderStartTime()} ${this.renderEndTime()}</div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleWorkHoursModalRow.tagName]: GoToScheduleWorkHoursModalRow
  }
}
