import { ShellElement } from '../../../common/shell-element'
import { html, state } from 'lit-element'
import { unsafeSVG } from 'lit-html/directives/unsafe-svg'
import { SVG_ADD_FILLED, SVG_CLOSE_CIRCLE_OUTLINED, SVG_REFRESH_FILLED } from '@getgo/chameleon-icons'
import { t } from '../../../directives/translate'
import { repeat } from 'lit-html/directives/repeat'
import { type WorkPeriod } from '../schedule-manager/models'
import { getScheduleManager } from '../schedule-manager/schedule-manager'
import scheduleWorkHoursModalStyles from './schedule-work-hours-modal.styles.scss'
import { asTranslationKey } from '../../../common/translate-helpers/i18n-utils'
import { getShellApiInstance } from '../../../common/shell-api-helpers'

export interface ModifiedWorkHoursEvent {
  readonly modifiedWorkPeriods: WorkPeriod[]
}

export const MODIFIED_WORK_PERIODS = 'modified-work-periods'
export const CLOSE_WORK_HOURS_MODAL = 'close-work-hours-modal'

export class GoToScheduleWorkHoursModal extends ShellElement {
  static readonly tagName = 'goto-schedule-work-hours-modal'

  @state() modifiedWorkPeriods: Partial<WorkPeriod>[] = []

  static get styles() {
    return scheduleWorkHoursModalStyles
  }

  connectedCallback() {
    super.connectedCallback()
    this.modifiedWorkPeriods = getScheduleManager().defaultWorkSchedule
  }

  private updateWorkPeriod(workPeriod: WorkPeriod, index: number) {
    this.modifiedWorkPeriods[index] = workPeriod
  }

  private deleteWorkPeriod(index: number) {
    const newWorkPeriod = [...this.modifiedWorkPeriods]
    newWorkPeriod.splice(index, 1)
    this.modifiedWorkPeriods = [...newWorkPeriod]
  }

  private addWorkPeriod() {
    this.modifiedWorkPeriods = [
      ...this.modifiedWorkPeriods,
      {
        dayOfWeek: undefined,
        startTime: undefined,
        endTime: undefined,
      },
    ]
  }

  private handleCancelClick() {
    this.dispatchEvent(new CustomEvent(CLOSE_WORK_HOURS_MODAL))
  }

  private handleConfirmClick() {
    const isWorkPeriod = (partialWorkPeriod: Partial<WorkPeriod>): partialWorkPeriod is WorkPeriod =>
      !!(partialWorkPeriod.dayOfWeek && partialWorkPeriod.startTime && partialWorkPeriod.endTime)

    const validatedWorkPeriods = this.modifiedWorkPeriods.filter(workPeriod => isWorkPeriod(workPeriod))

    this.dispatchEvent(
      new CustomEvent<ModifiedWorkHoursEvent>(MODIFIED_WORK_PERIODS, {
        detail: { modifiedWorkPeriods: validatedWorkPeriods },
      }),
    )
  }

  private handleResetClick() {
    const modal = getShellApiInstance().display.modal({
      id: 'update-modal',
      title: asTranslationKey('Reset to default?'),
      closable: false,
      content: asTranslationKey('Your work schedule will be set to Monday through Friday, 9 AM to 5 PM.'),
      actions: [
        {
          label: asTranslationKey('Cancel'),
          handler: () => {
            modal.close()
          },
          variant: 'tertiary',

          size: 'medium',
        },
        {
          label: asTranslationKey('Reset'),
          handler: () => {
            this.modifiedWorkPeriods = getScheduleManager().defaultWorkSchedule
            modal.close()
          },
          size: 'medium',
        },
      ],
    })
  }

  private renderWorkPeriodRow(workPeriod: WorkPeriod, index: number) {
    return html`
      <div class="modal-row">
        <goto-schedule-work-hours-modal-row
          .currentWorkPeriod=${workPeriod}
          .change=${(w: WorkPeriod) => this.updateWorkPeriod(w, index)}
          ?isFirstRow=${index === 0}
        ></goto-schedule-work-hours-modal-row>
        <chameleon-icon-button @click=${() => this.deleteWorkPeriod(index)}
          ><chameleon-svg>${unsafeSVG(SVG_CLOSE_CIRCLE_OUTLINED)}</chameleon-svg></chameleon-icon-button
        >
      </div>
    `
  }

  private renderWorkPeriods() {
    return repeat(
      this.modifiedWorkPeriods,
      index => index,
      (workPeriod: WorkPeriod, index) => this.renderWorkPeriodRow(workPeriod, index),
    )
  }

  private renderAddRow() {
    return html`
      <chameleon-button class="add-row-button" variant="tertiary" @click=${() => this.addWorkPeriod()}>
        <chameleon-svg slot="start">${unsafeSVG(SVG_ADD_FILLED)}</chameleon-svg>
        ${t('Add time')}
      </chameleon-button>
    `
  }

  private renderActions() {
    return html` <div slot="actions" class="modal-footer">
      <div>
        <chameleon-button class="cancel-button" variant="tertiary" @click=${this.handleCancelClick}
          >${t('Cancel')}</chameleon-button
        >
        <chameleon-button class="confirm-button" @click=${this.handleConfirmClick}>${t('Confirm')}</chameleon-button>
      </div>
      <chameleon-button class="reset-button" variant="tertiary" @click=${this.handleResetClick}
        ><chameleon-svg slot="start">${SVG_REFRESH_FILLED}</chameleon-svg>${t('Reset to default')}</chameleon-button
      >
    </div>`
  }

  render() {
    return html`
      <chameleon-dialog open size="large">
        <div class="modal-header" slot="title">
          <chameleon-typography variant="heading-small">${t('Edit your work schedule')}</chameleon-typography>
        </div>
        <div class="modal-scrollable-content">${this.renderWorkPeriods()}${this.renderAddRow()}</div>
        ${this.renderActions()}
      </chameleon-dialog>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    readonly [GoToScheduleWorkHoursModal.tagName]: GoToScheduleWorkHoursModal
  }
}
